import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './pages/Main'; // 메인 페이지 컴포넌트
const pages = require.context('./pages/Sub', true, /\.js$/); // Sub 폴더 내의 모든 JS 파일 동적 로딩

function App() {
  // Sub 폴더 내의 파일들로 동적 라우트를 생성
  const dynamicRoutes = pages.keys().map((path) => {
    const PageComponent = pages(path).default;
    const pageId = path.replace('./', '').replace('.js', ''); // 파일명에서 .js 제거
    return <Route key={pageId} path={`/sub/${pageId}`} element={<PageComponent />} />;
  });

  useEffect(() => {
    // 쿠팡 배너 스크립트를 전역적으로 로드
    const script1 = document.createElement('script');
    script1.src = 'https://ads-partners.coupang.com/g.js';
    script1.async = true;
    script1.onload = () => {
      const script2 = document.createElement('script');
      script2.innerHTML = `
        new PartnersCoupang.G({
          id: 810359,
          template: 'banner',
          trackingCode: 'AF8823843',
          width: '728',
          height: '90'
        });
      `;
      document.body.appendChild(script2);

      const script3 = document.createElement('script');
      script3.innerHTML = `
        new PartnersCoupang.G({
          id: 810360,
          template: 'carousel',
          trackingCode: 'AF8823843',
          width: '90%',
          height: '140'
        });
      `;
      document.body.appendChild(script3);
    };

    document.body.appendChild(script1);

    return () => {
      // Cleanup 스크립트 (필요하면 추가)
      document.body.removeChild(script1);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* 메인 페이지 경로 */}
        <Route path="/" element={<Main />} />
        
        {/* 동적으로 생성된 서브 페이지 경로 */}
        {dynamicRoutes}
      </Routes>
    </Router>
  );
}

export default App;
