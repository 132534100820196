import React, { useEffect, useState } from 'react';

function Main() {
  const [visitCount, setVisitCount] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);

  useEffect(() => {
    // 개인 방문 횟수 확인 및 업데이트
    const visits = localStorage.getItem('visitCount');
    if (visits) {
      const newVisitCount = parseInt(visits) + 1;
      setVisitCount(newVisitCount);
      localStorage.setItem('visitCount', newVisitCount);
    } else {
      setVisitCount(1);
      localStorage.setItem('visitCount', 1);
    }

    // 총 방문자 수 확인 및 업데이트
    const totalVisits = localStorage.getItem('totalVisitors');
    if (totalVisits) {
      const newTotalVisitors = parseInt(totalVisits) + 1;
      setTotalVisitors(newTotalVisitors);
      localStorage.setItem('totalVisitors', newTotalVisitors);
    } else {
      setTotalVisitors(1);
      localStorage.setItem('totalVisitors', 1);
    }
  }, []);

  return (
    <div className="App">
      <header className="header">
        <nav className="navbar">
          <h1 className="logo">Wooahah - Welcome!</h1>
        </nav>
      </header>

      <main className="main-content">
        <p>당신은 이 사이트에 {visitCount}번째 방문하셨습니다.</p>
        <p>총 방문자 수는 {totalVisitors}명입니다.</p>
      </main>

      <footer className="footer">
        <p>회사이름: Dezine 대표: YOON</p>
        <p>사업자번호: 626-25-01105</p>
        <p>통신판매업신고번호: 2020-호평평내-419</p>
      </footer>
    </div>
  );
}

export default Main;
